<template>
  <v-container id="store" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Store Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>Store Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>Store Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table
      :headers="columns"
      :items="stores"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col>
              <router-link v-if="modulePermission.Create" :to="{ name: 'Add Store' }">
                <v-btn color="primary float-right" dark class="mt-4">Add Store</v-btn>
              </router-link>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Confirm Delete</v-card-title
              ><v-card-text class="text-center"
                >Are you sure to delete record ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" depressed @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="primary darken-1" depressed @click="deleteItemConfirm"
                  >Delete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="authorizeCloverDialog" max-width="500px">
            <v-card>
              <v-card-title>Clover Details for authorization.</v-card-title>
              <v-card-content>
                <v-col col="4">
                  <v-text-field
                    label="Clover App Id"
                    class="custom_margin"
                    v-model="clover_app_id"
                  />
                  <v-text-field
                    label="Clover App Secret"
                    class="custom_margin"
                    v-model="clover_app_secret"
                  />
                </v-col>
              </v-card-content>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="closeAuthorizeCloverDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="initiateAuthorization"
                  :disabled="clover_app_id == null || clover_app_secret == null"
                  >Authorize</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.is_clover_store`]="{ item }">
        <!-- {{ numbers[index] }} -->
        <label v-if="item.is_authenticated == true">
          <v-img src="clover-symbol.png" style="width:30px;"
        /></label>
        <v-btn
          v-if="item.is_clover_store == true && item.is_authenticated == false"
          type="button"
          color="primary"
          class="mr-0"
          @click="authorizeClover(item)"
          :loading="loading"
        >
          Authorize
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Store Product',
            params: { id: item._id },
          }"
        >
          <!-- <v-btn small class="mr-2">View Product of Store</v-btn> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on">mdi-store</v-icon>
            </template>
            <span>View Poducts Of Store</span>
          </v-tooltip>
        </router-link>
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Store',
            params: { id: item._id },
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Store',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
        <v-icon v-if="modulePermission.Delete" @click="deleteStore(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      moduleName: "Stores",
      modulePermission: [],
      search: "",
      stores: [],
      dialogDelete: false,
      deleteRecord: "",
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
      authorizeCloverDialog: false,
      selected_store_id: null,
      clover_app_id: null,
      clover_app_secret: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px",
        },
        { sortable: false, text: "Name", value: "name", width: "100px" },
        { sortable: false, text: "Address", value: "address", width: "180px" },
        {
          sortable: false,
          text: "Is Tobacco Store",
          value: "is_tobacco_store",
          width: "180px",
        },
        { sortable: false, text: "Phone Number", value: "phone_number" },
        { sortable: false, text: "Description", value: "description" },
        { sortable: false, text: "Timing", value: "timing" },
        { sortable: false, text: "Clover ", value: "is_clover_store" },
      ];
    },
  },
  methods: {
    getStores() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "store/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores.data;
            this.loading = false;
            this.totalPages = response.data.stores.last_page;
            this.startRecord = response.data.stores.from;
            this.endRecord = response.data.stores.to;
            this.totalRecord = response.data.stores.total;
            this.numbers = [];
            for (
              let num = response.data.stores.from;
              num <= response.data.stores.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getStores();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      this.dialogDelete = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/delete/" + this.deleteRecord)
        .then((response) => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getStores();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteStore(data) {
      this.dialogDelete = true;
      this.deleteRecord = data._id;
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getStores();
    },
    authorizeClover(item) {
      this.selected_store_id = item._id;
      this.authorizeCloverDialog = true;
    },
    closeAuthorizeCloverDialog() {
      this.authorizeCloverDialog = false;
    },
    initiateAuthorization() {
      const requestParams = {
        store_id: this.selected_store_id,
        clover_app_id: this.clover_app_id,
        clover_app_secret: this.clover_app_secret,
      };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "store/clover/update", requestParams)
        .then((response) => {
          if (response.status == 200) {
            const url =
              process.env.VUE_APP_Clover_URL +
              "/oauth/v2/authorize?client_id=" +
              this.clover_app_id;
            window.location.replace(url);
            this.authorizeCloverDialog = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getStores();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  },
};
</script>
<style>
.custom_margin {
  margin: 0 25px;
}
</style>
